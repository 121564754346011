<template>
  <v-chart
    :ref="chartID"
    :id="chartID"
    :style="estilo"
    :option="option"
    :autoresize="true"
    :loading="dataSet == null ? true : false"
    @legendselectchanged="recalcLabels"
  />
</template>


<script>
import { mapState, mapActions } from "vuex";
import * as echarts from "echarts";
import VChart, { THEME_KEY } from "vue-echarts";

export default {
  name: "AreaChart",
  props: {
    chartID: {
      type: String,
      required: true,
    },
    estilo: {
      type: String,
      default: "width: 100%; height: 420px;",
    },
    chartTitle: {
      type: String,
    },
    selDataSet: {
      type: String,
      required: true,
    },
    yAxisUnit: {
      type: String,
      required: true,
    },
    yAxisLabel: {
      type: Boolean,
      default: true,
    },
    dataSet: {
      type: Object,
    },
    basicMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "v-chart": VChart,
  },
  data() {
    return {
      myChart: null,
      option: {
        title: {
          text: "CAUDALES EN 2022",
          x: "center",
          padding: [15, 0, 0, 0],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: [],
          selected: {},
          x: "center", //The legend can be set to the left, right and center
          y: "bottom", //The legend can be set to the top, bottom and center
          padding: [0, 0, 10, 0], //Legend can be set [distance from the top, distance from the right, distance from the bottom, distance from the left]
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "5%",
          right: "3%",
          bottom: "10%",
          top: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: this.yAxisUnit,
            nameLocation: "middle",
            nameGap: 40,
          },
        ],
        series: [],
      },
    };
  },
  computed: {
    cabeceras: function (val) {
      try {
        let headers = new Array();
        if (this.dataSet && this.selDataSet && this.dataSet[this.selDataSet]) {
          this.dataSet[this.selDataSet].columnas.forEach(function (col) {
            if (col.label != "Total Aducido") {
              headers.push(col.label);
            }
          });
        }
        return headers;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    xPoints: function (val) {
      try {
        let xPoints = new Array();
        if (this.dataSet && this.selDataSet && this.dataSet[this.selDataSet]) {
          this.dataSet[this.selDataSet].filas.forEach(function (row) {
            xPoints.push(row.fecha);
          });
        }
        return xPoints;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    yPoints: async function (val) {
      try {
        let yPoints = new Array();
        if (this.dataSet && this.selDataSet && this.dataSet[this.selDataSet]) {
          let rawDataSet = this.dataSet[this.selDataSet].filas;

          // Ahora rellenamos los data con los puntos de cara serie
          for (let i = 0; i < this.cabeceras.length; i++) {
            // Comprobamos que exista la cabecera
            if (
              this.cabeceras[i] != "" &&
              this.cabeceras[i] != "Total Aducido"
            ) {
              // Estructura de datos iniciales
              let dataStruct = {
                name: this.cabeceras[i],
                type: "line",
                stack: "Total",
                areaStyle: {},
                label: {
                  show:
                    this.cabeceras.length - 1 == i && this.yAxisLabel == true
                      ? true
                      : false,
                  /*formatter: this.genFormatter(series),*/
                  formatter: null,
                  position: "top",
                },
                emphasis: {
                  focus: "series",
                },
                data: [],
              };

              //Meter en el array
              yPoints.push(dataStruct);
            }
          }

          // Ahora rellenamos los data con los puntos de cara serie
          for (let i = 0; i < yPoints.length; i++) {
            for (let z = 0; z < rawDataSet.length; z++) {
              for (let n = 0; n < rawDataSet[z].masasAgua.length; n++) {
                if (yPoints[i].name == rawDataSet[z].masasAgua[n].label) {
                  if (
                    rawDataSet[z].masasAgua[n].caudal &&
                    rawDataSet[z].masasAgua[n].caudal.value
                  ) {
                    yPoints[i].data.push(
                      parseFloat(
                        rawDataSet[z].masasAgua[n].caudal.value != "-"
                          ? rawDataSet[z].masasAgua[n].caudal.value
                          : 0
                      )
                    );
                  } else {
                    yPoints[i].data.push(
                      parseFloat(
                        rawDataSet[z].masasAgua[n].extraido.value != "-"
                          ? rawDataSet[z].masasAgua[n].extraido.value
                          : 0
                      )
                    );
                  }
                }
              }
            }
          }

          /* Formateo del ultimo valor */
          yPoints[yPoints.length - 1].label.formatter =
            this.genFormatter(yPoints);

          // Devolvemos el resutlado
        }
        return yPoints;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
  },
  watch: {
    dataSet: async function (newVal, oldVal) {
      if (newVal != null) {
        await this.forceRefresh()
      }
    },
    
    'dataSet.planAduccion': async  function (newVal, oldVal) {
      if (newVal != null) {
        await this.forceRefresh()
      }
    },   

    'dataSet.planVolumen': async  function (newVal, oldVal) {
      if (newVal != null) {
        await this.forceRefresh()
      }
    },      
  },
  async mounted() {
    // Display the chart using the configuration items and data just specified.
    this.option.title.text = await this.chartTitle;
    this.option.series = await this.yPoints;
    this.option.xAxis[0].data = await this.xPoints;
    this.option.legend.data = await this.cabeceras;
    /* S se solicita el modo basico reajustamos los parametros */
    if (this.basicMode == true) {
      this.option.toolbox.show = await false;
      this.option.legend.padding = await [20, 0, 0, 0];
      this.option.grid = await {
        left: "8%",
        right: "3%",
        bottom: "10%",
        top: "5%",
        containLabel: true,
      };
    }
  },
  methods: {
    /* Generador de formatos */
    genFormatter(series) {
      return (param) => {
        let sum = 0;
        series.forEach((item) => {
          sum += item.data[param.dataIndex];
        });
        return sum.toFixed(2);
      };
    },

    /* Recalcular la suma de etiquetas */
    recalcLabels(obj) {
      console.log(this.option)
    },

    /* Force refresh */
    async forceRefresh(){
        this.option.title.text = await this.chartTitle;
        this.option.series = await this.yPoints;
        this.option.xAxis[0].data = await this.xPoints;
        this.option.legend.data = await this.cabeceras;
        for(let z=0; z<this.cabeceras.length; z++){
          this.option.legend.selected[this.cabeceras[z]] = true
        }      
    }
  },
};
</script>